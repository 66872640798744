import { endpoint, ResponseType } from '~/shared/api';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { Transaction } from '.';
const transactionHistoryURL = (address: string) =>
  endpoint(`/v1/account/${address}/transactions`);

const singleTxURL = (hash: string) => endpoint(`/v1/transaction/${hash}`);

export function useAccountTransactions({
  address,
}: {
  address: string | undefined;
}) {
  const data = useSWR<ResponseType<Transaction[]>>(
    () => (address ? 'transactions-' + address : null),
    () => {
      invariant(address, 'address is required');
      return fetch(transactionHistoryURL(address)).then((res) => res.json());
    }
  );

  return data;
}

export function useSingleTransaction({ hash }: { hash: string | undefined }) {
  const data = useSWR<ResponseType<Transaction>>(
    () => (hash ? 'transactions-' + hash : null),
    () => {
      invariant(hash, 'hash is required');
      return fetch(singleTxURL(hash)).then((res) => res.json());
    }
  );

  return data;
}
