import { ChevDownIcon, Icon } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import {
  ButtonHTMLAttributes,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from '~/shared/hooks/useClickOutside';

const AccountMenuOpener = styled.div((props) => ({
  borderRadius: '99999px',
  padding: `${props.theme.spacer * 2}px ${props.theme.spacer * 4}px`,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  letterSpacing: '-0.02em',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    backgroundColor: props.theme.colors.neutral[30],
  },
}));

const OpenerIcon = styled.div((props) => ({
  marginLeft: 'auto',
  paddingLeft: props.theme.spacer * 1,
  pointerEvents: 'none',
}));

const AccountActions = styled.div((props) => ({
  backgroundColor: props.theme.colors.neutral[10],
  padding: `${props.theme.spacer * 3}px 0`,
  top: 'calc(100% + 16px)',
  position: 'absolute',
  width: 200,
  minHeight: 48,
  borderRadius: 16,
  boxShadow: 'box-shadow: 0px 8px 20px rgba(39, 16, 69, 0.04)',
  right: 0,
  border: `1px solid ${props.theme.colors.neutral[20]}`,
}));

type AccountActionsItemProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    icon?: React.ReactNode;
    color?: string;
  }
>;

function _AccountActionItem({
  children,
  icon,
  color,
  ...rest
}: AccountActionsItemProps) {
  return (
    <button {...rest}>
      <Icon css={{ paddingRight: '12px' }}>{icon}</Icon>{' '}
      <span
        css={{
          marginRight: 'auto',
        }}
      >
        {children}
      </span>
    </button>
  );
}

export const ActionMenuItem = styled(_AccountActionItem)((props) => ({
  color: props.color || props.theme.colors.neutral[100],
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '-0.02em',
  fontWeight: 500,
  padding: `8px 20px`,
  '&:hover': {
    backgroundColor: props.theme.colors.neutral[20],
  },
}));

export function ActionMenu({
  children,
  actions,
  ...rest
}: PropsWithChildren<{ actions: React.ReactNode }>) {
  const [isOpen, setIsOpen] = useState(false);
  const openerEl = useRef<HTMLDivElement | null>(null);
  const actionsEl = useRef<HTMLDivElement | null>(null);

  useClickOutside({
    onClickedOutside: (e: MouseEvent) => {
      setIsOpen(false);
    },
    ref: openerEl,
    shouldListen: isOpen,
  });

  return (
    <AccountMenuOpener
      ref={(el) => {
        openerEl.current = el;
      }}
      onClick={(e) => {
        // ignore clicks on children
        if (e.target === actionsEl.current) {
          return;
        }

        setIsOpen(!isOpen);
      }}
      {...rest}
    >
      {children}
      <OpenerIcon>
        <ChevDownIcon />
      </OpenerIcon>

      <AccountActions
        ref={(el) => {
          actionsEl.current = el;
        }}
        css={{ display: isOpen ? 'block' : 'none' }}
      >
        {actions}
      </AccountActions>
    </AccountMenuOpener>
  );
}
