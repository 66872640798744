import { BodyPrimaryText, Button, Buttons, Icon, Title } from '~/shared/ui/kit';
import { MerchantLoginViewContainer } from '../styles';
import sendFailIcon from './send-failure.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

const DEFAULT_MESSAGE = 'There was an error sending you a link';

export function MerchantLoginFailView() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const { t } = useTranslation('merchant');

  return (
    <MerchantLoginViewContainer>
      <Title h={2} as={'h2'} css={{ textAlign: 'center', marginBottom: 32 }}>
        Oops..
      </Title>
      <div
        css={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 32,
        }}
      >
        <Icon>
          <img src={sendFailIcon} alt="send fail icon" />
        </Icon>
        <BodyPrimaryText
          css={{
            color: theme.colors.neutral[60],
          }}
        >
          {state && state.errorMessage
            ? state.errorMessage
            : t('login.linkSentErrorMessage')}
        </BodyPrimaryText>
      </div>
      <Buttons nButtons={1}>
        <Button
          onClick={() => navigate('/merchant/login')}
          semanticType="primary"
        >
          {t('login.linkSentErrorButton')}
        </Button>
      </Buttons>
    </MerchantLoginViewContainer>
  );
}
