import { createBrowserRouter } from 'react-router-dom';
import { FF_PLAYNANCE_INTEGRATION } from '../feature-flags';

import { IframeApp } from '../entries/iframe-app';
import { WalletApp } from '../entries/wallet-app';
import { isNotNull } from '~/shared/ts-utilities';

export const router = createBrowserRouter(
  [
    FF_PLAYNANCE_INTEGRATION
      ? {
          path: '/iframe/*',
          element: <IframeApp />,
        }
      : null,
    {
      path: '/*',
      element: <WalletApp />,
    },
  ].filter(isNotNull),
  {}
);
