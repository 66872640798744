import invariant from 'tiny-invariant';
import { useTransaction, useWaitForTransaction } from 'wagmi';
import { Transaction, useSingleTransaction } from '~/entities/transactions';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { SafeTransactionResponse } from './lib/types';
import { TransactionReceipt } from 'viem';

export function TransactionLoader({
  txHash,
  renderTransaction,
  renderLoading,
}: {
  txHash: string;
  renderTransaction: (data: {
    transaction: Transaction;
    ethTransaction: SafeTransactionResponse;
    receipt: TransactionReceipt;
  }) => React.ReactElement;
  renderLoading: (status: 'waiting' | 'fetching') => React.ReactElement;
}) {
  const activeChain = useActiveChain();
  const txWait = useWaitForTransaction({
    chainId: activeChain,
    hash: txHash as `0x${string}`,
    confirmations: 1,
  });

  const transactionRequest = useSingleTransaction({
    hash: txWait.isSuccess ? (txHash as `0x${string}`) : undefined,
  });
  const ethTransactionRequest = useTransaction({
    chainId: activeChain,
    hash: txWait.isSuccess ? (txHash as `0x${string}`) : undefined,
  });

  // TODO: better loading states
  if (!txWait.isSuccess || !txWait.data) {
    return renderLoading('waiting');
  } else if (
    transactionRequest.isLoading ||
    ethTransactionRequest.isLoading ||
    !transactionRequest.data
  ) {
    return renderLoading('fetching');
  }

  const transaction = transactionRequest.data.data;
  const ethTransaction = ethTransactionRequest.data;
  invariant(transaction, 'transaction data is not found');
  invariant(ethTransaction, 'eth transaction data is not found');

  transaction.to = transaction.to || '';
  transaction.from = transaction.from || '';
  transaction.value = transaction.value || 0;
  ethTransaction.gasPrice = ethTransaction.gasPrice || BigInt(0);

  return renderTransaction({
    transaction: transaction,
    ethTransaction: ethTransaction as SafeTransactionResponse,
    receipt: txWait.data,
  });
}
