import {
  erc20ABI,
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  usePrepareSendTransaction,
  useSendTransaction,
} from 'wagmi';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { HexString } from '~/shared/types';
import { useFeeEstimation } from './use-fee-estimation';
import { isMatic } from '~/entities/tokens';
import { useEffect, useState } from 'react';
import { TokenMetadata } from '~/entities/tokens';
import { getContract } from '@wagmi/core';
import { toWeiBigInt } from '~/shared/ethereum/units';
import { RPCError } from '../types';
import { encodeFunctionData } from 'viem';
import { SendTransactionResult } from 'wagmi/dist/actions';

export type SendConfig = {
  to: HexString;
  value: bigint;
  from: HexString;
  data?: HexString;
};
/**
 * Transform transfer request into transaction data
 * Automatically fills `data` field for ERC20 transfers
 * @param param0
 * @returns
 */
export async function prepareTransferParameters({
  amount,
  token,
  to,
  from,
}: {
  amount: string;
  token: TokenMetadata;
  to: HexString;
  from: HexString;
}) {
  const usingMatic = isMatic(token.address);
  let config: SendConfig;

  if (usingMatic) {
    config = {
      to: to,
      value: toWeiBigInt(amount, token?.decimals || 0),
      from: from,
    };
  } else {
    const contract = getContract({
      address: token.address as HexString,
      abi: erc20ABI,
    });

    if (!contract) {
      throw new Error(
        'prepareTransferParameters: Contract is null. Provided token has no contract address. Impossible to populate transaction.'
      );
    }

    const data = encodeFunctionData({
      abi: erc20ABI,
      functionName: 'transfer',
      args: [to, toWeiBigInt(amount, token?.decimals || 0)],
    });

    config = {
      to: token.address as HexString,
      data: data,
      value: BigInt(0),
      from: from,
    };
  }

  return config;
}

export function useManualTxPrepare({
  amount,
  token,
  to,
  from,
}: {
  amount: string;
  token: TokenMetadata;
  to: HexString;
  from: HexString;
}): SendConfig | undefined {
  const [txConfig, setConfig] = useState<SendConfig | undefined>(undefined);
  useEffect(() => {
    let didCancel = false;
    const doTask = async () => {
      const config = await prepareTransferParameters({
        amount,
        token,
        to,
        from,
      });
      if (!didCancel) {
        setConfig(config);
      }
    };

    doTask();

    return () => {
      didCancel = true;
    };
  }, [amount, token, to, from, token.decimals]);

  return txConfig;
}

// function fixNonceType(tx: ) {
//   if (tx.nonce) {
//     const nonce = tx.nonce;

//     return {
//       ...tx,
//       nonce: tx.nonce,
//     };
//   }
// }

export function useTokenSending({
  amount,
  token,
  to,
}: {
  amount: string;
  token: TokenMetadata;
  to: HexString;
}): {
  error: Error | null | undefined | RPCError;
  fee: ReturnType<typeof useFeeEstimation>;
  config?: SendConfig;
  sender: ReturnType<typeof useSendTransaction | typeof useContractWrite>;
  submit?: () => Promise<SendTransactionResult>;
} {
  const activeChain = useActiveChain();
  const account = useAccount();

  const weiAmount = toWeiBigInt(amount, token?.decimals || 0);
  const usingMatic = isMatic(token.address);

  const prepTokenSend = usePrepareContractWrite({
    chainId: activeChain,
    address: token?.address as HexString,
    enabled: !!token && !usingMatic,
    abi: erc20ABI,
    functionName: 'transfer',
    args: [to, weiAmount],
  });

  const data = encodeFunctionData({
    abi: erc20ABI,
    functionName: 'transfer',
    args: [to, weiAmount],
  });

  const tokenConfig: SendConfig | undefined = account.address
    ? {
        to: token.address as HexString,
        from: account.address,
        value: BigInt(0),
        data: data,
      }
    : undefined;

  const prepMaticSend = usePrepareSendTransaction({
    chainId: activeChain,
    to: to,
    value: weiAmount,
    enabled: !!token && usingMatic,
    cacheTime: 20000,
  });

  const maticConfig: SendConfig | undefined = account.address
    ? {
        to: to,
        from: account.address,
        value: weiAmount,
        // TODO: Maybe add 0x0
        // data: '0x0',
      }
    : undefined;

  let prepared = usingMatic ? maticConfig : tokenConfig;

  // .request
  //     ? {
  //         from: config.request.from,
  //         to: config.request.to,
  //         value: BigNumber.from(config.request.value?.toString() || 0),
  //         data: config.request.data?.toString(),
  //       }
  //     : undefined,

  const fee = useFeeEstimation(
    prepared,
    usingMatic ? 'transfer' : 'erc20transfer'
  );

  const maticSend = useSendTransaction(prepMaticSend.config);
  const tokenSend = useContractWrite(prepTokenSend.config);
  const sender = usingMatic ? maticSend : tokenSend;

  return {
    fee: fee,
    error: fee.error || sender.error,
    config: prepared,
    sender: sender,
    submit: usingMatic ? maticSend.sendTransactionAsync : tokenSend.writeAsync,
  };
}
