import { ENV_RECAPTCHA_SITE_KEY } from '~/app/config';
import ReCAPTCHA from 'react-google-recaptcha';
import { forwardRef, useRef, ForwardedRef, MutableRefObject } from 'react';

const RECAPTCHA_PRIVACY_LINK =
  'https://www.google.com/intl/en/policies/privacy/';
const RECAPTCHA_TERMS_LINK = 'https://www.google.com/intl/en/policies/terms/';
const RECAPTCHA_LOGO = 'https://www.gstatic.com/recaptcha/api2/logo_48.png';

function isMutableRef<T extends any>(
  ref: ForwardedRef<T>
): ref is MutableRefObject<T> {
  return typeof ref === 'object' && ref !== null && 'current' in ref;
}

type ReCAPTCHASize = 'compact' | 'normal' | 'invisible';

export const Recaptcha = forwardRef<
  ReCAPTCHA | null,
  {
    onChange: (token: string) => void;
    size: ReCAPTCHASize;
  }
>(({ onChange, size, ...rest }, ref) => {
  return (
    <div {...rest}>
      <ReCAPTCHA
        ref={(rc) => {
          if (typeof ref === 'function') {
            ref(rc);
          } else if (isMutableRef<ReCAPTCHA | null>(ref)) {
            ref.current = rc;
          }
        }}
        sitekey={ENV_RECAPTCHA_SITE_KEY}
        onChange={(token) => onChange(token || '')}
        size={size}
      ></ReCAPTCHA>
    </div>
  );
});
