import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BodyPrimaryText,
  Button,
  CurtainLoader,
  Icon,
  NonWrappingButtons,
  SmallCenteredContainer,
  Title,
} from '~/shared/ui/kit';
import { Navigate } from 'react-router-dom';
import { useMerchantContext } from '~/entities/merchant';
import { useMerchantSettings } from '../../data-hooks';
import { useTheme } from '@emotion/react';
import { UserAvatarPlaceholder } from '~/pages/pay-with-quvio/ui/user-avatar-placeholder';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { Trans, useTranslation } from 'react-i18next';

function ReauthView({ ticket, email }: { ticket: string; email: string }) {
  const { t } = useTranslation('merchant');

  const merchant = useMerchantContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const info = useMerchantSettings();

  // wait until we get merchant info
  if (!info.data) {
    return <CurtainLoader text={t('login.welcome')}></CurtainLoader>;
  }

  // if user is logging in into the same account  - redirect to dashboard
  if (info.data?.data.email === email) {
    return <Navigate to={'/merchant/dashboard'}></Navigate>;
  }

  return (
    <SmallCenteredContainer
      css={{
        textAlign: 'center',
      }}
    >
      <Icon
        size={60}
        css={{
          marginBottom: 32,
        }}
      >
        <UserAvatarPlaceholder />
      </Icon>
      <Title h={3} as="div" css={{ marginBottom: 8 }}>
        {t('login.changeAccount')}
      </Title>
      <BodyPrimaryText
        as={'p'}
        css={{
          color: theme.colors.neutral[60],
          lineHeight: 1.4,
        }}
      >
        <Trans
          i18nKey={'login.changeAccountDescription'}
          t={t}
          tOptions={{
            currentEmail: info.data.data.email,
            newEmail: email,
          }}
          components={[
            <b css={{ color: theme.colors.neutral[100] }} />,
            <b css={{ color: theme.colors.neutral[100] }} />,
          ]}
        />
      </BodyPrimaryText>
      <NonWrappingButtons nButtons={1} css={{ marginTop: 32 }}>
        <Button
          semanticType="primary"
          onClick={() => {
            merchant.login(ticket);
          }}
        >
          {t('login.useNewEmailButton')} {trimMiddle(email || '', 20)}
        </Button>
        <Button
          semanticType="secondary"
          onClick={() => {
            navigate('/merchant/dashboard');
          }}
        >
          {t('login.useCurrentEmailButton')}{' '}
          {trimMiddle(info.data.data.email, 20)}
        </Button>
      </NonWrappingButtons>
    </SmallCenteredContainer>
  );
}

export function AuthView({ ticket, email }: { ticket: string; email: string }) {
  const merchant = useMerchantContext();
  const { t } = useTranslation('merchant');

  // UseEffect is run twice due to React strict mode. It only happens in development mode.
  // but still highly annoying. This is a workaround.
  // https://github.com/remix-run/react-router/issues/9652
  // https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
  useEffect(() => {
    if (!ticket) {
      return;
    }

    merchant.login(ticket);
  }, [ticket]);

  if (!ticket) {
    return <Navigate to={'/merchant/login'}></Navigate>;
  }

  return <CurtainLoader text={t('login.welcome')}></CurtainLoader>;
}

export function MerchantLoginEntryView() {
  const merchant = useMerchantContext();
  const [search] = useSearchParams();
  const ticket = search.get('ticket');
  const email = search.get('email') || '';

  if (!ticket) {
    return <Navigate to={'/merchant/login'}></Navigate>;
  } else if (merchant.isAuthenticated) {
    return <ReauthView ticket={ticket} email={email} />;
  }

  return <AuthView ticket={ticket} email={email} />;
}
