import { Props as ReactModalProps } from 'react-modal';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CopyAddress } from '~/features/copy-address';
// import { useFiatValue } from '~/shared/fiat-converter';
import {
  formatImgUrl,
  inheritNftCollectionMetadata,
  useNftPrice,
} from '~/entities/nfts';
import {
  Modal,
  LinkButton,
  Button,
  SecondaryMaterial,
  media,
  ModalContentContainerMobileFullScreen,
} from '~/shared/ui/kit';
import { Nft } from 'alchemy-sdk';
import { NftCollectionTitle } from '~/entities/nfts/ui/NftCollectionTitle';
import { useState } from 'react';
import useSwr from 'swr';
import { NftMedia } from '~/entities/nfts/ui/NftMedia';

const NftWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  ...media('lg', {
    flexDirection: 'row',
  }),
}));

const NftImage = styled(NftMedia)({
  borderRadius: '28px',
  width: '100%',
  ...media('lg', {
    maxWidth: 520,
  }),
});

const NftInfo = styled.div({
  ...media('lg', {
    marginLeft: 40,
  }),
});

const NftTitle = styled.h2({
  fontSize: 20,
  fontWeight: 700,
  ...media('md', {
    fontSize: 24,
  }),
  ...media('lg', {
    fontSize: 28,
  }),
});

const Description = styled.p({
  margin: '20px 0',
  fontSize: 14,
  ...media('md', {
    fontSize: 16,
  }),
});

const PriceWrapper = styled.div({
  paddingBottom: '16px',
});

const Label = styled.span((props) => ({
  color: props.theme.colors.neutral[60],
  fontSize: 12,
  ...media('md', {
    fontSize: 14,
  }),
  ...media('lg', {
    fontSize: 16,
  }),
}));

const NftPrice = styled.span({
  fontWeight: 700,
  paddingRight: '8px',
  fontSize: 16,
  ...media('md', {
    fontSize: 20,
  }),
  ...media('lg', {
    fontSize: 24,
  }),
});

const HintText = styled.p(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.neutral[60],
  textAlign: 'center',
}));

const trimDescription = (str: string, chars = 350) => {
  const isShortened = str.length > chars;
  let content = str;

  if (isShortened) {
    content = `${str.slice(0, chars)}...`;
  }

  return { isShortened, content };
};

function NftInfoModalContent({
  nft,
  onTransfer,
}: {
  nft: Nft;
  onTransfer: (nft: Nft) => void;
}) {
  const { t } = useTranslation('common');
  const [showFull, setShowFull] = useState(false);

  const { isShortened, content } = trimDescription(
    nft.description || '',
    showFull ? Infinity : 350
  );

  // const price = useNftPrice({ nft });
  // const fiatValue = useFiatValue({});

  return (
    <NftWrapper
      css={{
        flexGrow: 1,
      }}
    >
      <NftImage
        token={nft}
        css={{
          ...media(
            { max: 'lg' },
            {
              display: 'none',
            }
          ),
        }}
      />
      <NftInfo
        css={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NftCollectionTitle
          collection={inheritNftCollectionMetadata(nft)}
          css={{
            marginBottom: 12,
            fontSize: 14,
            ...media('md', {
              fontSize: 16,
            }),
          }}
        ></NftCollectionTitle>
        <div
          css={{
            ...media(
              { max: 'lg' },
              {
                marginTop: 16,
                marginBottom: 16,
              }
            ),
          }}
        >
          <NftTitle>{nft.name}</NftTitle>
          <CopyAddress address={nft.contract.address} maxShownLength={24} />
        </div>

        <NftImage
          token={nft}
          css={{
            ...media('lg', {
              display: 'none',
            }),
          }}
        />

        <Description>
          {content}
          {isShortened && !showFull && (
            <LinkButton
              css={{
                display: 'block',
              }}
              onClick={() => {
                setShowFull(true);
              }}
            >
              Show more
            </LinkButton>
          )}
        </Description>

        <SecondaryMaterial
          css={{
            marginTop: 'auto',
            padding: 12,
            ...media('md', {
              padding: 20,
            }),
            ...media('lg', {
              padding: 24,
            }),
          }}
        >
          {/* <Label>Floor Price</Label>
          <PriceWrapper>
            <NftPrice>Price is not available</NftPrice>
          </PriceWrapper> */}
          {/* <PriceWrapper>
            {price.firstPrice ? (
              <>
                <NftPrice>
                  {price.firstPrice.floorPrice} {price.firstPrice.priceCurrency}
                </NftPrice>{' '}
                <Label>$100</Label>
              </>
            ) : (
              <NftPrice>Price is not available</NftPrice>
            )}
          </PriceWrapper> */}
          <Button
            semanticType="secondary"
            css={{ width: '100%' }}
            onClick={() => onTransfer(nft)}
            disabled={true}
          >
            {t('nft.transferToSomeone')}
          </Button>
          <HintText
            css={{
              marginTop: 12,
            }}
          >
            NFT sending will be available soon. Thanks for staying with us!
          </HintText>
        </SecondaryMaterial>
      </NftInfo>
    </NftWrapper>
  );
}

export function NftInfoModal({
  nft,
  onTransfer,
  ...rest
}: ReactModalProps & {
  nft: Nft | null;
  onTransfer: (nft: Nft) => void;
}) {
  return (
    <Modal
      {...rest}
      css={{
        maxWidth: 1080,
        width: '100%',
      }}
      id="NftInfoModal"
      ContentComponent={ModalContentContainerMobileFullScreen}
    >
      {nft ? <NftInfoModalContent nft={nft} onTransfer={onTransfer} /> : null}
    </Modal>
  );
}
