import { createContext, useContext } from 'react';
import { ENV_NETWORK } from '~/app/config';

/**
 * There is hook useNetwork available in @wagmi but for some reason it does not provide accurate information
 * Either a bug, or I am using it wrong.
 * This is a workaround for now.
 */

const MAINNET_CHAIN_ID = '0x89';
const MAINNET_CHAIN_ID_INT = Number.parseInt(MAINNET_CHAIN_ID, 16);

const TESTNET_CHAIN_ID = '0x13881';
const TESTNET_CHAIN_ID_INT = Number.parseInt(TESTNET_CHAIN_ID, 16);

const ChainContext = createContext({
  chainId: TESTNET_CHAIN_ID_INT,
});

export function ChainProvider({ children }: { children: React.ReactNode }) {
  const chainId =
    ENV_NETWORK === 'mainnet' ? MAINNET_CHAIN_ID_INT : TESTNET_CHAIN_ID_INT;

  return (
    <ChainContext.Provider value={{ chainId }}>
      {children}
    </ChainContext.Provider>
  );
}

export function useActiveChain() {
  const { chainId } = useContext(ChainContext);
  return chainId;
}
