import { Nft } from 'alchemy-sdk';
import { SecondaryMaterial } from '~/shared/ui/kit';
import { NftThumbnail } from './NftThumbnail';
import { inheritNftCollectionMetadata } from '../utils';
import { trimMiddle } from '~/shared/utils/string-helpers';
import styled from '@emotion/styled';

const NftTitle = styled.h4({
  fontSize: 20,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '100%',
  width: 0,
});

const NftContractAddress = styled.p({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.28,
});

export function NftItemCard({ nft, ...rest }: { nft: Nft }) {
  return (
    <SecondaryMaterial
      css={{
        padding: 12,
        display: 'flex',
        alignItems: 'center',
      }}
      {...rest}
    >
      <NftThumbnail
        token={nft}
        css={{
          marginRight: 16,
          width: 64,
          height: 64,
          borderRadius: 14,
        }}
      />

      <div
        css={{
          flexGrow: 1,
        }}
      >
        <NftTitle css={{ marginBottom: 4 }}>{nft.name}</NftTitle>
        <NftContractAddress>
          {trimMiddle(nft.contract.address, 16)}
        </NftContractAddress>
      </div>
    </SecondaryMaterial>
  );
}
