import styled from '@emotion/styled';

export const ModalHeading = styled.h2((props) => ({
  fontSize: 24,
  fontWeight: 900,
  lineHeight: '117%',
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[100],
}));

export const ModalSubHeading = styled.p((props) => ({
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[60],
}));
