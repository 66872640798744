import React from 'react';

import { CardListItem, SublineText, useMediaQuery } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Transaction } from '..';
import { Icon, TxInIcon, TxOutIcon } from '~/shared/ui/kit';
import { css } from '@emotion/react';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { FiatBalance, useFiatValue } from '~/shared/fiat-converter';
import { formatValue } from '~/entities/tokens/utils';
import { MATIC_TOKEN } from '~/entities/tokens/tokens';
import { useTranslation } from 'react-i18next';
import { ViewInExplorerMini } from '~/entities/explorer';

type TransactionItemProps = PropsWithChildren<{
  tx: Transaction;
  account: string;
  fiatValue?: FiatBalance;
}>;

const titles = {
  sent: 'transactions.sent',
  received: 'transactions.received',
  self: ' transactions.self',
};

const StyledTransactionItem = styled(CardListItem)((props) => ({
  padding: '0',
}));

const IconContainer = styled.div<{
  isReceived: boolean;
}>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: 14,
  backgroundColor: props.isReceived
    ? props.theme.colors.success[20]
    : props.theme.colors.neutral[20],
}));

const TxValue = styled.div<{ isReceived: boolean }>((props) => ({
  color: props.isReceived
    ? props.theme.colors.success[120]
    : props.theme.colors.neutral[80],
  fontWight: 500,
  fontSize: 16,
  letterSpacing: '-0.02em',
}));

const TxValueFiat = styled.div((props) => ({
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[60],
}));

function _TransactionItem({ account, tx, ...rest }: TransactionItemProps) {
  const { t } = useTranslation('common');

  const isMd = useMediaQuery('md');
  const isSent = tx.from.toLowerCase() === account.toLowerCase();
  const isSelf = tx.from.toLowerCase() === tx.to.toLowerCase();
  const isReceived = tx.to.toLowerCase() === account.toLowerCase();

  const trimAddressIfNeeded = (address: string) => {
    return isMd ? address : trimMiddle(address, 12);
  };
  const title = isSelf
    ? t(titles.self)
    : isSent
    ? t(titles.sent)
    : t(titles.received);
  const subline = isSent
    ? t('glossary.to') + ': ' + trimAddressIfNeeded(tx.to)
    : isReceived
    ? t('glossary.from') + ': ' + trimAddressIfNeeded(tx.from)
    : account;

  const fiatValue = useFiatValue({
    value: tx.value,
    symbol: tx.asset,
  });

  return (
    <StyledTransactionItem
      isOutlined={false}
      title={
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
          <ViewInExplorerMini
            txHash={tx.hash}
            size={14}
            css={{
              marginLeft: 4,
            }}
          />
        </div>
      }
      subline={<SublineText css={css({ fontSize: 12 })}>{subline}</SublineText>}
      image={
        <IconContainer isReceived={isReceived}>
          {isReceived ? (
            <Icon>
              <TxInIcon />
            </Icon>
          ) : isSent ? (
            <Icon>
              <TxOutIcon />
            </Icon>
          ) : (
            <span>?</span>
          )}
        </IconContainer>
      }
      controls={
        <>
          <TxValue isReceived={isReceived}>
            {isReceived ? '+' : '-'}
            {formatValue(
              tx.value,
              tx.rawContract.decimal
                ? Number.parseInt(tx.rawContract.decimal, 16)
                : MATIC_TOKEN.decimals
            )}{' '}
            {tx.asset}
          </TxValue>

          {
            /* TODO: calculate fiat value  */
            <TxValueFiat>{fiatValue.formatted}</TxValueFiat>
          }
        </>
      }
      {...rest}
    ></StyledTransactionItem>
  );
}

export const TransactionItem = styled(_TransactionItem)((props) => ({}));
