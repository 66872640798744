import { NftTransferForm, TransferFormStep } from '~/features/transfer-nfts';
import { Modal } from '~/shared/ui/kit';
import { Props as ReactModalProps } from 'react-modal';
import { Nft } from 'alchemy-sdk';

export function TransferNftsModal(
  props: ReactModalProps & {
    nft: Nft | null;
  }
) {
  return (
    <Modal
      {...props}
      css={{
        maxWidth: 480,
        width: '100%',
      }}
    >
      {props.nft ? (
        <NftTransferForm
          nft={props.nft}
          onClose={(e: React.MouseEvent) =>
            props.onRequestClose ? props.onRequestClose(e) : null
          }
        />
      ) : (
        ''
      )}
    </Modal>
  );
}
