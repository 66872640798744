import { TransactionLoader } from '~/entities/transactions/transaction-loader';
import { BodyPrimaryText, Title, LoadingDots } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import { TransactionReceipt } from './ui/transaction-receipt';
import { useTranslation } from 'react-i18next';

const Container = styled.div({
  minHeight: 400,
});

export function ReceiptStep({
  txHash,
  onClose,
}: {
  txHash: string;
  onClose: (e: React.MouseEvent) => void;
}) {
  const { t } = useTranslation('common');

  return (
    <TransactionLoader
      txHash={txHash}
      renderTransaction={({ transaction, ethTransaction, receipt }) => (
        <Container>
          <TransactionReceipt
            transaction={transaction}
            ethTransaction={ethTransaction}
            receipt={receipt}
            onClose={onClose}
          />
        </Container>
      )}
      renderLoading={(status) =>
        status === 'waiting' ? (
          <Container>
            <Title
              as="h3"
              h={3}
              css={{ marginTop: 56, marginBottom: 24, textAlign: 'center' }}
            >
              {t('sendTokens.statusWaitingTitle')}
              <LoadingDots />
            </Title>
            <BodyPrimaryText as="p" css={{ textAlign: 'center' }}>
              {t('sendTokens.statusDescription')}
            </BodyPrimaryText>
          </Container>
        ) : (
          <Container>
            <Title
              as="h3"
              h={3}
              css={{ marginTop: 56, marginBottom: 24, textAlign: 'center' }}
            >
              {t('sendTokens.statusFetchingTitle')}
              <LoadingDots />
            </Title>
            <BodyPrimaryText as="p" css={{ textAlign: 'center' }}>
              {t('sendTokens.statusDescription')}
            </BodyPrimaryText>
          </Container>
        )
      }
    ></TransactionLoader>
  );
}
