import { formatFiatValue } from '~/entities/tokens/utils';
import { Transaction } from '~/entities/transactions';
import {
  Button,
  Buttons,
  CheckOutlinedIcon,
  Delimiter,
  FormItemDisplay,
  ModalHeading,
  OutlinedIcon,
} from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useFiatValue } from '~/shared/fiat-converter';
import { ViewInExplorer } from '~/entities/explorer';
import { TransactionNetworkFee } from '../transaction-network-fee';
import { BigNumber } from '@ethersproject/bignumber';
import { SafeTransactionResponse } from '~/entities/transactions/lib/types';
import { useTranslation } from 'react-i18next';
import { TransactionReceipt as TTRansactionReceipt } from 'viem';

const ReceiptSummary = styled.div((props) => ({
  backgroundColor: props.theme.colors.success[20],
  borderRadius: 28,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

type TransactionReceiptProps = React.PropsWithChildren<{
  transaction: Transaction;
  ethTransaction: SafeTransactionResponse;
  receipt: TTRansactionReceipt;
  onClose: (e: React.MouseEvent) => void;
}>;

export function TransactionReceipt({
  transaction,
  ethTransaction,
  receipt,
  onClose,
  ...rest
}: TransactionReceiptProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const fiatValue = useFiatValue({
    value: transaction.value,
    symbol: transaction.asset,
  });

  const fee = receipt.gasUsed * receipt.effectiveGasPrice;
  return (
    <>
      {
        //TODO move title out
      }
      <ModalHeading css={{ marginBottom: 20 }}>
        {t('sendTokens.sentSuccesfully')}
      </ModalHeading>
      <ReceiptSummary css={{ marginBottom: 24 }}>
        <OutlinedIcon
          size={'large'}
          color={theme.colors.success[40]}
          css={{
            color: theme.colors.success[120],
            marginBottom: 12,
          }}
        >
          <CheckOutlinedIcon />
        </OutlinedIcon>
        <div
          css={{
            fontWeight: 900,
            fontSize: 20,
            letterSpacing: '-0.02em',
          }}
        >
          -{transaction.value.toString()} {transaction.asset}
        </div>
        <div>
          ≈ {fiatValue.symbol}
          {formatFiatValue(fiatValue.fiatValue || 0)}
        </div>
      </ReceiptSummary>

      <FormItemDisplay
        name={t('glossary.asset')}
        value={transaction.asset.toString()}
        css={{ marginBottom: 24 }}
      />
      <FormItemDisplay
        name={t('glossary.from')}
        value={trimMiddle(transaction.from, 20)}
        css={{ marginBottom: 24 }}
      />
      <FormItemDisplay
        name={t('glossary.to')}
        value={trimMiddle(transaction.to, 20)}
        css={{ marginBottom: 24 }}
      />

      <Delimiter css={{ marginBottom: 24 }} />

      <TransactionNetworkFee
        feeMatic={BigNumber.from(fee.toString())}
        css={{ marginBottom: 24 }}
      />
      <ViewInExplorer txHash={transaction.hash} css={{ marginBottom: 24 }} />

      <Buttons nButtons={1}>
        <Button semanticType="primary" onClick={(e) => onClose(e)}>
          {t('sendTokens.backToWallet')}
        </Button>
      </Buttons>
    </>
  );
}
