import { useFeeData, usePublicClient } from 'wagmi';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { useEffect, useMemo, useState } from 'react';
import { MATIC_TOKEN } from '~/entities/tokens/tokens';
import invariant from 'tiny-invariant';
import { useFiatValue } from '~/shared/fiat-converter';
import { BigNumber } from '@ethersproject/bignumber';
import { toEthers } from '~/shared/ethereum/units';
import { TransferType, defaultGasLimit } from '~/shared/ethereum/gas';
import { RPCError } from '../types';
import { SendConfig } from './use-token-sending';

function serializeTx(tx: SendConfig) {
  return JSON.stringify({
    ...tx,
    value: tx.value.toString(),
  });
}

export function useFeeEstimation(
  transactionConfig?: SendConfig,
  fallbackMode: TransferType = 'unknown'
) {
  const activeChain = useActiveChain();
  const provider = usePublicClient({
    chainId: activeChain,
  });
  const [feeEstimation, setFeeEstimation] = useState<string>('');
  const [error, setError] = useState<Error | RPCError | null>(null);

  const feeData = useFeeData({
    chainId: activeChain,
  });

  let gasPrice = feeData.data && feeData.data.gasPrice;

  const serializedTx = useMemo(() => {
    return transactionConfig ? serializeTx(transactionConfig) : '';
  }, [transactionConfig]);

  useEffect(() => {
    let didCancel = false;

    const doEstimations = async (config: SendConfig) => {
      invariant(config, 'config is required');

      let estimations: BigInt = BigInt(0);
      let error: Error | null = null;
      try {
        estimations = await provider.estimateGas({
          to: config.to,
          data: config.data,
          value: config.value,
          account: config.from,
          gasPrice: gasPrice || BigInt(16),
        });
      } catch (_err) {
        // Try to find best estimation for transaction fee
        estimations = defaultGasLimit(fallbackMode);
        error = _err as Error | RPCError;
      }

      if (didCancel) return;

      setError(error);
      setFeeEstimation(estimations.toString());
    };

    if (transactionConfig && provider && feeData.data) {
      doEstimations(transactionConfig);
    }

    return () => {
      didCancel = true;
    };
    // serializedTx is used as a dependency to trigger the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedTx, provider, feeData.data]);

  let maticFee = BigNumber.from(0);

  if (feeEstimation && feeData.data) {
    maticFee = BigNumber.from(feeEstimation).mul(
      feeData.data.gasPrice?.toString() || 0
    );
  }

  const fiatFeeValue = useFiatValue({
    value: toEthers(maticFee.toString(), MATIC_TOKEN.decimals).toUnsafeFloat(),
    symbol: MATIC_TOKEN.symbol,
  });

  return {
    feeMatic: maticFee,
    feeFiat: fiatFeeValue,
    error,
  };
}
