import { Nft } from 'alchemy-sdk';
import { makeAutoObservable } from 'mobx';

export class NftTransferModel {
  token: Nft | null = null;
  recepient: string = '';
  transaction: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.token = null;
    this.recepient = '';
    this.transaction = '';
  }

  updateToken(token: Nft) {
    this.reset();
    this.token = token;
  }
}

export const nftTransferModel = new NftTransferModel();
