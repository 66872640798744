import React from 'react';

export const useModalControl = () => {
  const [activeModal, setActiveModal] = React.useState<string>('');

  return {
    open: (name: string) => setActiveModal(name),
    close: () => setActiveModal(''),
    isActive: (name: string) => activeModal === name,
  };
};
