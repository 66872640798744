import { Connector, createConfig } from 'wagmi';
import {
  ENV_RPC_URL,
  ENV_NETWORK,
  ENV_ALCHEMY_API_KEY,
  ENV_WEB3_CLIENT_ID,
  ENV_WEB3_VERIFIER_NAME,
} from '~/app/config';

import { polygon, polygonMumbai } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { configureChains } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { CHAIN_NAMESPACES, getEvmChainConfig } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { whiteLabelConfig } from '~/white-label';
const { chains, publicClient } = configureChains(
  [polygon, polygonMumbai],
  [alchemyProvider({ apiKey: ENV_ALCHEMY_API_KEY })]
);

const mainnetChain = chains[0];
const testnetChain = chains[1];
const selectedChain = ENV_NETWORK === 'testnet' ? testnetChain : mainnetChain;
const chainConfig = getEvmChainConfig(selectedChain.id);
if (chainConfig === null) {
  throw new Error('Chain config not found');
}

const web3authNetwork = 'cyan'; //ENV_NETWORK === 'testnet' ? 'testnet' : 'cyan';

const openloginAdapter = new OpenloginAdapter({
  privateKeyProvider: new EthereumPrivateKeyProvider({
    config: {
      chainConfig: chainConfig,
    },
  }),
  adapterSettings: {
    // using "popup" has issues with browsers blocking popups
    uxMode: 'redirect',
    whiteLabel: {
      name: whiteLabelConfig.name,
      logoLight: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
      logoDark: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
      defaultLanguage: 'en',
      dark: false, // whether to enable dark mode. defaultValue: false
    },
    loginConfig: {
      jwt: {
        verifier: ENV_WEB3_VERIFIER_NAME,
        typeOfLogin: 'jwt',
        clientId: ENV_WEB3_CLIENT_ID,
      },
    },
  },
});

export const web3AuthInstance = new Web3AuthNoModal({
  clientId: ENV_WEB3_CLIENT_ID,
  web3AuthNetwork: web3authNetwork,
  chainConfig: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x' + selectedChain.id.toString(16),
    rpcTarget: ENV_RPC_URL,
    displayName: selectedChain.name,
    tickerName: selectedChain.nativeCurrency.name,
    ticker: selectedChain.nativeCurrency.symbol,
    blockExplorer: selectedChain.blockExplorers?.default.url,
  },
}).configureAdapter(openloginAdapter);

export const web3AuthConnector = new Web3AuthConnector({
  chains,
  options: {
    web3AuthInstance,
  },
});

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors: [
    web3AuthConnector as unknown as Connector,
    new InjectedConnector({ chains }),
  ],
  publicClient,
});
