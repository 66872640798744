import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAccount } from 'wagmi';

import { TokenList, useAccountTokens } from '~/entities/tokens';
import { NftList, useAccountNfts, useActiveNft } from '~/entities/nfts';
import {
  CardBase,
  CardTitle,
  OutlinedIcon,
  SublineText,
  UserIcon,
  media,
} from '~/shared/ui/kit';
import {
  TransactionList,
  useAccountTransactions,
} from '~/entities/transactions';
import { Summary } from './ui/summary';
import { WalletActions } from './ui/actions';
import { useUserTotalBalance } from '~/features/balance';
import { ReceiveModal } from '~/widgets/receive-modal';
import { SendTokensModal } from '~/widgets/send-tokens-modal';
import { NftInfoModal } from '~/widgets/nft-info-modal';
import { TransferNftsModal } from '~/widgets/transfer-nfts-modal';
import { useModalControl } from '~/shared/hooks/useModalControl';
import { useTracker } from '~/shared/analytics';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '~/shared/ethereum/web3auth';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { TabSwitcher } from '~/shared/ui/kit/tab-switcher';
import { useState } from 'react';
import { Nft } from 'alchemy-sdk';

const Layout = styled.div((props) => ({
  display: 'grid',
  gridGap: props.theme.spacer * 5,
  maxWidth: 1064,
  margin: '0 auto',
  marginTop: props.theme.spacer * 5,
  ...media('lg', {
    gridTemplateColumns: '3fr 2fr',
  }),
}));

export function MainPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { address } = useAccount();
  const userInfo = useUserInfo();

  const tokens = useAccountTokens({ address });
  const { ownedNftsByCollection } = useAccountNfts({ address });
  const [activeNft, setActiveNft] = useActiveNft();
  const transactions = useAccountTransactions({
    address,
  });

  const tracker = useTracker();
  const { open, close, isActive } = useModalControl();

  const totalBalance = useUserTotalBalance({
    symbol: 'usd',
  });
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Layout>
      <ReceiveModal
        isOpen={isActive('receive')}
        onRequestClose={() => close()}
      />
      <SendTokensModal
        isOpen={isActive('send-tokens')}
        onRequestClose={() => close()}
      />
      <NftInfoModal
        isOpen={isActive('nft-info')}
        onRequestClose={() => {
          close();
        }}
        onTransfer={(nft: Nft) => {
          close();
          setActiveNft(nft);
          open('transfer-nfts');
        }}
        nft={activeNft}
      />
      <TransferNftsModal
        isOpen={isActive('transfer-nfts')}
        onRequestClose={() => {
          close();
          setActiveNft(null);
        }}
        nft={activeNft}
      />

      <CardBase>
        <SublineText
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <OutlinedIcon
            size={20}
            css={{
              marginRight: 8,
            }}
          >
            <UserIcon></UserIcon>
          </OutlinedIcon>
          <span>{trimMiddle(userInfo.data?.email || '', 28)}</span>
        </SublineText>

        <Summary
          account={address || ''}
          balance={totalBalance.value || 0}
          balanceSymbol={totalBalance.symbol === 'usd' ? '$' : '€'}
        />
        <WalletActions
          onAction={(action) => {
            if (action === 'send') {
              tracker.track('wallet.click_send_tokens');
              open('send-tokens');
            } else if (action === 'receive') {
              tracker.track('wallet.click_receive_tokens');
              open('receive');
            } else if (action === 'offramp') {
              navigate('/offramp');
            } else if (action === 'onramp') {
              navigate('/onramp');
            }
          }}
          css={css({
            marginTop: 20,
          })}
        />
        <div
          css={css({
            marginTop: 40,
            ...media('md', {
              minHeight: 400,
            }),
          })}
        >
          <CardTitle
            css={css({
              marginBottom: 16,
            })}
          >
            {t('glossary.transactions')}
          </CardTitle>
          <TransactionList
            account={address || ''}
            txs={transactions.data?.data || []}
          ></TransactionList>
        </div>
      </CardBase>

      <div>
        <CardBase style={{ marginBottom: 20 }}>
          <TabSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            css={{
              marginBottom: 20,
            }}
          >
            <span>{t('glossary.assets')}</span>
            <span>{t('nft.title')}</span>
          </TabSwitcher>
          {activeTab === 0 ? (
            <TokenList tokens={tokens.data?.data || []}></TokenList>
          ) : (
            <NftList
              nftCollections={ownedNftsByCollection}
              onOpenNft={(token) => {
                setActiveNft(token);
                open('nft-info');
              }}
            />
          )}
        </CardBase>
      </div>
    </Layout>
  );
}
