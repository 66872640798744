import { observer } from 'mobx-react-lite';
import { NftItemCard } from '~/entities/nfts/ui/NftItemCard';
import { NftTransferModel } from './model';
import { Nft } from 'alchemy-sdk';
import { ModalHeading } from '~/shared/ui/kit';

function _AddressStep({ nft }: { nft: Nft }) {
  return (
    <div>
      <ModalHeading css={{ marginBottom: 16 }}>Transfer</ModalHeading>
      <NftItemCard
        nft={nft}
        css={{
          marginBottom: 40,
        }}
      />
    </div>
  );
}

export const AddressStep = observer(
  ({ nftTransferModel }: { nftTransferModel: NftTransferModel }) => {
    if (!nftTransferModel.token) {
      return <div>Nft is null</div>;
    }

    return _AddressStep({ nft: nftTransferModel.token });
  }
);
