import {
  BodyPrimaryText,
  Button,
  Buttons,
  Icon,
  LinkButton,
  Title,
} from '~/shared/ui/kit';
import { MerchantLoginViewContainer } from '../styles';
import sendSuccessICon from './send-success.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export function MerchantLoginSuccessView() {
  const { t } = useTranslation('merchant');
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();

  return (
    <MerchantLoginViewContainer>
      <Title h={2} as={'h2'} css={{ textAlign: 'center', marginBottom: 32 }}>
        {t('login.linkSentTitle')}
      </Title>
      <div
        css={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 32,
        }}
      >
        <Icon>
          <img src={sendSuccessICon} alt="send success icon" />
        </Icon>
        <BodyPrimaryText
          css={{
            color: theme.colors.neutral[60],
          }}
        >
          {t('login.linkSentDescription')}
        </BodyPrimaryText>
      </div>
      <Buttons nButtons={1}>
        <Button
          onClick={() => navigate('/merchant/login')}
          semanticType="primary"
        >
          {t('login.linkSentButtonText')}
        </Button>
      </Buttons>

      {state && state.ticket ? (
        <Link to={`/merchant/login/entry?ticket=${state.ticket}`}>
          <LinkButton as={'span'}>{t('login.devLogin')}</LinkButton>
        </Link>
      ) : null}
    </MerchantLoginViewContainer>
  );
}
