import {
  StepProgressProvider,
  useStepProgress,
} from '~/shared/hooks/useStepProgress';
import { AddressStep } from './address-step';
import { ConfirmStep } from './confirm-step';
import { ReceiptStep } from './receipt-step';
import { useEffect } from 'react';
import { NftTransferModel, nftTransferModel } from './model';
import { observer } from 'mobx-react-lite';
import { TransferFormStep, transferFormSteps } from './types';
import { Nft } from 'alchemy-sdk';

function TransferFormRenderer({
  onClose,
}: {
  onClose: (e: React.MouseEvent) => void;
}) {
  const steps = useStepProgress<TransferFormStep>();

  if (steps.step === 'enterRecipient') {
    return <AddressStep nftTransferModel={nftTransferModel} />;
  } else if (steps.step === 'confirm') {
    return <ConfirmStep />;
  } else if (steps.step === 'success') {
    return <ReceiptStep onClose={onClose} />;
  }

  return null;
}

export const TransferForm = observer(
  ({
    nftTransferModel,
    onClose,
  }: {
    nftTransferModel: NftTransferModel;
    onClose: (e: React.MouseEvent) => void;
  }) => {
    return <TransferFormRenderer onClose={onClose} />;
  }
);

export function NftTransferForm({
  nft,
  onClose,
}: {
  nft: Nft;
  onClose: (e: React.MouseEvent) => void;
}) {
  useEffect(() => {
    nftTransferModel.updateToken(nft);
  }, [nft]);

  return (
    <StepProgressProvider
      steps={transferFormSteps}
      initialStep="enterRecipient"
    >
      <TransferForm nftTransferModel={nftTransferModel} onClose={onClose} />
    </StepProgressProvider>
  );
}
